import { useUser } from '@auth0/nextjs-auth0';
import { useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import dynamic from 'next/dynamic';
import { Suspense, useEffect } from 'react';
import { usePusher } from '../lib/context/PusherContext';
import usePrivilege from '../lib/hooks/usePrivilege';
import Layout from '../components/layout';
import BasicHeader from '../components/layout/BasicHeader';
import useTable from '../components/shared/custom_table/useTable';
import Spin from '../components/ui/Spin';

const DynamicSlideOver = dynamic(() => import('../components/ui/SlideOver'), { suspense: true });
const DynamicInvoiceDetail = dynamic(() => import('../components/InvoiceDetail/InvoiceDetail'), { suspense: true });
const CustomTable = dynamic(() => import('../components/shared/custom_table'), { suspense: true });

const CustomTableFilters = dynamic(() => import('../components/shared/custom_table_filters'), { suspense: true });
const PUSHER_CHANNEL = process.env.NEXT_PUBLIC_PUSHER_CHANNEL;

export default function Home() {
	const { user } = useUser();
	const { userOnRestrictedPage } = usePrivilege();
	const queryClient = useQueryClient();
	const { current: pusher } = usePusher();
	const {
		data,
		pagination,
		loading,
		selectedInvoice,
		openDetails,
		toggleDetails,
		columns,
		handleTableChange,
		handleSearch,
		handleDateRange
	} = useTable({ endpoint: 'invoices', tableId: 'approval' });

	const handleApprovalError = (data) => {
		console.log('APPROVAL ERROR :: ', data);
		if (data.userName === user.name) {
			message.error('There was an issue updating this invoice in Business Central..');
			message.info(`Error Message: ${data.message}`);
		}
	};

	const handleApprovalSuccess = (data) => {
		console.log('APPROVAL SUCCESS :: ', data);
		if (data.userName === user.name) {
			message.success('Your approval has been successfully recorded in Business Central!');
		}
		queryClient.invalidateQueries('approval');
	};

	const handleApprovalBegin = (data) => {
		console.log('APPROVAL BEGIN :: ', data);
		if (data.userName === user.name) {
			message.info('Your approval is being processed in Business Central. This may take a moment');
		}
		queryClient.invalidateQueries('approval');
	};

	const handleStatusUpdate = (data) => {
		console.log('STATUS UPDATE :: ', data);
		queryClient.invalidateQueries({ queryKey: ['approval'] });
	};

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			const channel = pusher.subscribe(PUSHER_CHANNEL);
			channel.bind('post-treasury-approval-begin', handleApprovalBegin);
			channel.bind('post-treasury-approval-end', handleApprovalSuccess);
			channel.bind('post-treasury-approval-error', handleApprovalError);
			channel.bind('invoice-status-update', handleStatusUpdate);
		}

		return () => {
			pusher.unsubscribe(PUSHER_CHANNEL);
			mounted = false;
		};
	}, []);

	if (userOnRestrictedPage('/')) {
		return <Spin />;
	}

	return (
		<div className="animate-fadeIn">
			<Suspense fallback={<Spin />}>
				<DynamicSlideOver
					className="z-50"
					title="Invoice Detail"
					open={openDetails}
					setOpen={toggleDetails}
					panelWidth={'max-w-5xl'}
				>
					<DynamicInvoiceDetail
						invoice={selectedInvoice}
						setOpen={toggleDetails}
						showApprovalBtn={
							selectedInvoice && selectedInvoice.WEI_Treasury_Status === 'Needs Review' ? false : true
						}
						isFinalReview={selectedInvoice && selectedInvoice.WEI_Treasury_Status === 'Needs Review'}
					/>
				</DynamicSlideOver>
			</Suspense>
			<Suspense fallback={<Spin />}>
				<BasicHeader
					containerClass="pb-5"
					title={<span className="page-sub-title">{`Welcome back, ${user.given_name}!`}</span>}
					subTitle={
						pagination && pagination.total > 0
							? `${pagination.total} purchase invoices are awaiting your approval.`
							: ''
					}
				/>
				<CustomTableFilters handleSearch={handleSearch} handleDateRange={handleDateRange} tableId="approval" />
				<CustomTable
					data={data}
					loading={loading}
					pagination={pagination}
					columns={columns}
					handleTableChange={handleTableChange}
					handleSearch={handleSearch}
					multi={true}
					user={user}
					expandable={{
						expandedRowRender: (record) => (
							<p
								style={{
									margin: 0
								}}
							>
								<span className="font-bold">Lumen Description: </span>
								{record.WEI_Lumen_Description ? (
									record.WEI_Lumen_Description
								) : (
									<span className="italic">None Provided</span>
								)}
							</p>
						),
						rowExpandable: (record) => true
					}}
				/>
			</Suspense>
		</div>
	);
}

Home.getLayout = function getLayout(page) {
	return <Layout>{page}</Layout>;
};
