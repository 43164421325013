import moment from 'moment';

export function formatAsUSD(number, currency) {
	if (currency) {
		const currencyFormatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: currency
		});
		return currencyFormatter.format(number || 0);
	} else {
		const currencyFormatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD'
		});
		return currencyFormatter.format(number || 0);
	}
}

export function totalAmounts(data) {
	return data
		.map((e) => e?.Amount)
		.reduce((partial, a) => partial + a, 0)
		.toFixed(2);
}

export async function priorityCheck(data) {
	for (let inv in data) {
		const invoice = data[inv];
		if (invoice.WEI_High_Priority_Flag) return true;
	}
	return null;
}

export async function handlingCheck(data) {
	for (let inv in data) {
		const invoice = data[inv];
		if (
			invoice?.WEI_Handling_Description &&
			typeof invoice?.WEI_Handling_Description === 'string' &&
			invoice?.WEI_Handling_Description != ''
		) {
			return 'This batch has special handling instructions';
		}
	}
	return null;
}

export const deCamel = (text) => {
	if (typeof text == 'string') {
		let result = text.replace(/([A-Z])/g, ' $1');
		return result.charAt(0).toUpperCase() + result.slice(1);
	} else {
		return text;
	}
};

export const searchFilter = (fields) => {
	const criteriaMatch = [];
	fields.field.forEach((f) => {
		criteriaMatch.$match.$or.push({
			[f]: {
				$regex: `.*${fields.value.replaceAll(' ', '.*')}.*`,
				$options: 'i'
			}
		});
	});
	return criteriaMatch;
};

export const needsSecondApproval = (invoice, isExternalVendor, singleApprover) => {
	if (singleApprover) return false; // doesn't need second approval

	if (isExternalVendor) {
		// if external vendor, only need second approval if amount is greater than $100,000
		return invoice.Amount && invoice.Amount > 99999.99;
	}
	return false;
};

export const getSessionStorage = (key) => {
	const stored = sessionStorage.getItem(key);
	return stored ? JSON.parse(stored) : null;
};

export const humanReadable = (bytes) => {
	const i = Math.floor(Math.log(bytes) / Math.log(1024));
	return `${(bytes / Math.pow(1024, i)).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
};

export const dateFormatStyle = 'MMM Do, YYYY h:mm A';

export const formatDate = (dateTime) => {
	return moment(dateTime).format(dateFormatStyle);
};

const deactivateInvoices = async (bucket, prefix, userId) => {
	try {
		const [files] = await bucket.getFiles({
			prefix: `${prefix}/primary_document`
		});
		for (let f in files) {
			const file = files[f];
			await bucket.file(file.name).move(`deactivated/${file.name}`);
		}
		return true;
	} catch (error) {
		console.log(error);
	}
};

/**
 * assumes array elements are primitive types; check whether 2 arrays are equal sets.
 * @param  {} a1 is an array
 * @param  {} a2 is an array
 */
export function areArraysEqualSets(a1, a2) {
	const superSet = {};
	for (const i of a1) {
		const e = i + typeof i;
		superSet[e] = 1;
	}

	for (const i of a2) {
		const e = i + typeof i;
		if (!superSet[e]) {
			return false;
		}
		superSet[e] = 2;
	}

	for (let e in superSet) {
		if (superSet[e] === 1) {
			return false;
		}
	}

	return true;
}

export function parseBatchType(batchType) {
	const [payFromOrg, payFromBank, payFromMethod] = batchType.split('-');
	return { payFromOrg, payFromBank, payFromMethod };
}

export function isInvalidBatchDocument(batch) {
	if (!batch) {
		return 'No batch provided to isInvalidBatchDocument()...';
	}

	if (!batch.Workflow) {
		return 'No Workflow in batch provided to isInvalidBatchDocument()...';
	}

	if (!batch.BatchType) {
		return 'No BatchType in batch provided to isInvalidBatchDocument()... ';
	}

	if (batch.BatchType.split('-').length !== 3) {
		return 'BatchType must be 3 strings, separated by hyphens.';
	}

	return false;
}
